export default defineAppConfig({
  ui: {
    primary: "teal",
    gray: "cool",

    accordion: {
      item: {
        padding: "p-3",
      },
    },

    alert: {
      title: "font-semibold mb-1",
    },

    avatar: {
      rounded: "overflow-hidden",
    },

    badge: {
      rounded: "rounded-full",
    },

    button: {
      rounded: "rounded-lg",
      font: "font-semibold",
      icon: {
        loading: "i-svg-spinners-90-ring-with-bg",
      },
    },

    dashboard: {
      card: {
        body: {
          base: "space-y-6",
        },
      },

      modal: {
        height: "h-auto",
        icon: {
          base: "text-primary-500 dark:text-primary-400",
        },
      },

      navbar: {
        wrapper: "dark:border-gray-700/50",
      },

      page: {
        wrapper: "bg-gray-50 dark:bg-gray-800",
      },

      panel: {
        content: {
          wrapper: "space-y-6",
        },
      },

      sidebar: {
        links: {
          active: "text-primary-500 dark:text-primary-300 before:bg-primary-50 dark:before:bg-primary-950",
          icon: {
            active: "text-primary-500 dark:text-primary-300",
          },
        },
      },

      toolbar: {
        wrapper: "dark:border-gray-700/50",
      },
    },

    formGroup: {
      label: {
        base: "text-gray-800 dark:text-gray-100 font-semibold",
      },
      help: "text-xs",
    },

    input: {
      rounded: "rounded-lg",
      default: {
        size: "md",
      },
    },

    notifications: {
      // Show toasts at the top right of the screen
      position: "top-0 bottom-auto",
    },

    pagination: {
      wrapper: "flex items-center gap-1",
      rounded: "!rounded-full min-w-[32px] justify-center",
      default: {
        activeButton: {
          variant: "outline",
        },
      },
    },

    selectMenu: {
      width: "min-w-fit",
    },

    table: {
      default: {
        loadingState: {
          icon: "i-svg-spinners-90-ring animate-none",
        },
      },
    },

    textarea: {
      rounded: "rounded-lg",
    },
  },
});
