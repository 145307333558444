import { default as _91id_93IWwtVr2KnfMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/chat/[id].vue?macro=true";
import { default as _91id_93oURd2YK0TKMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/chat/embed/[id].vue?macro=true";
import { default as publicppOsvpM8uGMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/chat/public.vue?macro=true";
import { default as indexlUDgOXheeGMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/index.vue?macro=true";
import { default as maintenance8fFVWdIUs0Meta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/maintenance.vue?macro=true";
import { default as sandbox7lVypxi3VgMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/sandbox.vue?macro=true";
import { default as signinpQw7jMl5fKMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/signin.vue?macro=true";
import { default as signoutx4H0Wte3y8Meta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/signout.vue?macro=true";
import { default as signupI2WYcQ1kQUMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/signup.vue?macro=true";
import { default as indexPB8ZDwRl8RMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/analytics/index.vue?macro=true";
import { default as sessionsQi7QTP479NMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue?macro=true";
import { default as analyticsHjFkXNZbJpMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/analytics.vue?macro=true";
import { default as _91id_93VxXyTfb7AlMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue?macro=true";
import { default as createWPO0BdKa2tMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/chatbots/create.vue?macro=true";
import { default as _91id_93HKNolaol6wMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue?macro=true";
import { default as indexfUhcZZTo4pMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/chatbots/index.vue?macro=true";
import { default as documentsDEq3kVnFZlMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/documents.vue?macro=true";
import { default as indexAgHJfnVjyYMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/index.vue?macro=true";
import { default as postcheckoutd9QlMMCBNYMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/postcheckout.vue?macro=true";
import { default as resetPasswordpdFoYZHSurMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/resetPassword.vue?macro=true";
import { default as sandboxHHKmauNxssMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/sandbox.vue?macro=true";
import { default as indexsTZohjbUxSMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/index.vue?macro=true";
import { default as integrationsuW6IiENs5IMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/integrations.vue?macro=true";
import { default as legalIiYUI4LRnqMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/legal.vue?macro=true";
import { default as organizationlfxSbjaOkZMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/organization.vue?macro=true";
import { default as planS6rb9dTsc1Meta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/plan.vue?macro=true";
import { default as settingsI7XdMR6kZTMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings.vue?macro=true";
import { default as support1GQNyq6p6OMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/support.vue?macro=true";
import { default as teamt7nGuqJE1oMeta } from "/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/team.vue?macro=true";
export default [
  {
    name: "chat-id___en",
    path: "/chat/:id()",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-id___de",
    path: "/de/chat/:id()",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-embed-id___en",
    path: "/chat/embed/:id()",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/chat/embed/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-embed-id___de",
    path: "/de/chat/embed/:id()",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/chat/embed/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-public___en",
    path: "/chat/public",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/chat/public.vue").then(m => m.default || m)
  },
  {
    name: "chat-public___de",
    path: "/de/chat/public",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/chat/public.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___en",
    path: "/maintenance",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___de",
    path: "/de/maintenance",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "sandbox___en",
    path: "/sandbox",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "sandbox___de",
    path: "/de/sandbox",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "signin___en",
    path: "/signin",
    meta: signinpQw7jMl5fKMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signin___de",
    path: "/de/signin",
    meta: signinpQw7jMl5fKMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signout___en",
    path: "/signout",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/signout.vue").then(m => m.default || m)
  },
  {
    name: "signout___de",
    path: "/de/signout",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/signout.vue").then(m => m.default || m)
  },
  {
    name: "signup___en",
    path: "/signup",
    meta: signupI2WYcQ1kQUMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "signup___de",
    path: "/de/signup",
    meta: signupI2WYcQ1kQUMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: analyticsHjFkXNZbJpMeta?.name,
    path: "/user/analytics",
    meta: analyticsHjFkXNZbJpMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "user-analytics___en",
    path: "",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "user-analytics-sessions___en",
    path: "sessions",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: analyticsHjFkXNZbJpMeta?.name,
    path: "/de/user/analytics",
    meta: analyticsHjFkXNZbJpMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "user-analytics___de",
    path: "",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "user-analytics-sessions___de",
    path: "sessions",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-chatbots-chat-id___en",
    path: "/user/chatbots/chat/:id()",
    meta: _91id_93VxXyTfb7AlMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-chat-id___de",
    path: "/de/user/chatbots/chat/:id()",
    meta: _91id_93VxXyTfb7AlMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-create___en",
    path: "/user/chatbots/create",
    meta: createWPO0BdKa2tMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/chatbots/create.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-create___de",
    path: "/de/user/chatbots/create",
    meta: createWPO0BdKa2tMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/chatbots/create.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-edit-id___en",
    path: "/user/chatbots/edit/:id()",
    meta: _91id_93HKNolaol6wMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-edit-id___de",
    path: "/de/user/chatbots/edit/:id()",
    meta: _91id_93HKNolaol6wMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots___en",
    path: "/user/chatbots",
    meta: indexfUhcZZTo4pMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/chatbots/index.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots___de",
    path: "/de/user/chatbots",
    meta: indexfUhcZZTo4pMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/chatbots/index.vue").then(m => m.default || m)
  },
  {
    name: "user-documents___en",
    path: "/user/documents",
    meta: documentsDEq3kVnFZlMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/documents.vue").then(m => m.default || m)
  },
  {
    name: "user-documents___de",
    path: "/de/user/documents",
    meta: documentsDEq3kVnFZlMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/documents.vue").then(m => m.default || m)
  },
  {
    name: "user___en",
    path: "/user",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user___de",
    path: "/de/user",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-postcheckout___en",
    path: "/user/postcheckout",
    meta: postcheckoutd9QlMMCBNYMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/postcheckout.vue").then(m => m.default || m)
  },
  {
    name: "user-postcheckout___de",
    path: "/de/user/postcheckout",
    meta: postcheckoutd9QlMMCBNYMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/postcheckout.vue").then(m => m.default || m)
  },
  {
    name: "user-resetPassword___en",
    path: "/user/resetPassword",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "user-resetPassword___de",
    path: "/de/user/resetPassword",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "user-sandbox___en",
    path: "/user/sandbox",
    meta: sandboxHHKmauNxssMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "user-sandbox___de",
    path: "/de/user/sandbox",
    meta: sandboxHHKmauNxssMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/sandbox.vue").then(m => m.default || m)
  },
  {
    name: settingsI7XdMR6kZTMeta?.name,
    path: "/user/settings",
    meta: settingsI7XdMR6kZTMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "user-settings___en",
    path: "",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-integrations___en",
    path: "integrations",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/integrations.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-legal___en",
    path: "legal",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/legal.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-organization___en",
    path: "organization",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/organization.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-plan___en",
    path: "plan",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: settingsI7XdMR6kZTMeta?.name,
    path: "/de/user/settings",
    meta: settingsI7XdMR6kZTMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "user-settings___de",
    path: "",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-integrations___de",
    path: "integrations",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/integrations.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-legal___de",
    path: "legal",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/legal.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-organization___de",
    path: "organization",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/organization.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-plan___de",
    path: "plan",
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/settings/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-support___en",
    path: "/user/support",
    meta: support1GQNyq6p6OMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/support.vue").then(m => m.default || m)
  },
  {
    name: "user-support___de",
    path: "/de/user/support",
    meta: support1GQNyq6p6OMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/support.vue").then(m => m.default || m)
  },
  {
    name: "user-team___en",
    path: "/user/team",
    meta: teamt7nGuqJE1oMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/team.vue").then(m => m.default || m)
  },
  {
    name: "user-team___de",
    path: "/de/user/team",
    meta: teamt7nGuqJE1oMeta || {},
    component: () => import("/codebuild/output/src4251161132/src/DocuChatv2/web-app/pages/user/team.vue").then(m => m.default || m)
  }
]